const team = [
  {
    name: "Chinmaya Kale ",
    position: "President",
    imgurl: "chin.png",
    instagram: "https://www.instagram.com/chinmaya.kale/",
    linkedin: "https://www.linkedin.com/in/chinmaya-kale-1661a7230/",
    phone: "+91 8452029034",
  },
  {
    name: "Atharava Jadhav",
    imgurl: "ata.png",
    position: "Vice President",
    instagram: "https://www.instagram.com/_.glorfindle._/",
    linkedin: "https://www.linkedin.com/in/atharav-jadhav/",
    phone: "+91 6389614492",
  },
  {
    name: "Drishti Diwani",
    position: "Head Of Content Creation",
    imgurl: "drish.png",
    instagram: "https://www.instagram.com/me_dishi/",
    linkedin: "https://www.linkedin.com/in/drishti-diwani-a6539b22a/",
    phone: "+91 9373829927",
  },
  // {
  //   name: "Aashish Mishra",
  //   position: "Treasurer",
  //   instagram: "http://instagram.com",
  //   linkedin: "http://linkedin.com",
  // },
  {
    name: "Mayank Singh",
    position: "Head Of Technology",
    imgurl: "mayank.png",
    instagram: "https://www.instagram.com/mayank_4451/",
    linkedin: "https://www.linkedin.com/in/mayank-singh-452945246/",
    phone: "+91 9654703319",
  },
  {
    name: "Harsh Sherki",
    position: "Head Of Public Relations",
    imgurl: "harsh.png",
    instagram: "https://www.instagram.com/harshsherki/",
    linkedin: "https://www.linkedin.com/in/harsh-sherki-475926283/",
    phone: "+91 9730200931",
  },
  {
    name: "Jai Vardhan Shukla",
    position: "Head Of Design",
    imgurl: "jai.png",
    instagram: "",
    linkedin: "https://www.linkedin.com/in/jaivardhan-shukla-3633a2190/",
    phone: "+91 7028420121",
  },
  {
    name: "Jayanthi Nunavath",
    position: "Head Of Research",
    imgurl: "jaya.png",
    instagram: "https://www.instagram.com/jayanthinunavath1994/",
    linkedin: "",
    phone: "+91 9146462230",
  },

   {
     name: "Anurag yadav",
     position: "Head Of MultiMedia",
     imgurl:"anurag.png",
     instagram: "https://www.instagram.com/itsanu_03/",
     linkedin: "http://linkedin.com",
   },
  {
     name: "Khusboo Garg",
     position: "PG Affairs Secretery",
     imgurl:"kush.png",
     instagram: "https://www.instagram.com/khushboo___garg/",
     linkedin: "http://linkedin.com",
     phone:"+91 9178563423"
   },
  {
    name: "Karan Chavan",
    position: "Treasurer",
    imgurl: "karan.jpg",
    instagram: "https://www.instagram.com/karan_chavan123/",
    linkedin: "https://www.linkedin.com/in/karan-chavan-3bb9b0223/",
    phone: "+91 7350873585",
  },
];

export default team;
